<template>
  <div class="mb-3">
    <b-tabs pills>
      <b-tab title="All" active lazy>
        <notification-list :type="'all'" />
      </b-tab>
      <b-tab activ title="Cases" lazy>
        <notification-list :type="'UPDATE_CASE'" />
      </b-tab>
      <b-tab title="Hearings" lazy>
        <notification-list :type="'WHATSAPP_CAUSELIST'" />
      </b-tab>
      <b-tab title="Orders" lazy>
        <notification-list :type="'ORDER_NEW'" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import NotificationList from './notification-list/NotificationList.vue'
import { BTabs, BTab, BButton } from 'bootstrap-vue'

export default {
  components: {
    NotificationList,
    BTabs,
    BTab,
    BButton
  }
}
</script>
