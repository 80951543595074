<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <template #header>
      <b-col>
        <b-row class="justify-content-between px-2 pt-50">
          <h6 class="m-0">Notifications</h6>
          <p
            class="text-primary cursor-pointer m-0"
            @click.prevent="onReadAllClick"
          >
            Read All
          </p>
        </b-row>
      </b-col>
    </template>
    <b-overlay
      :show="$store.state.notificationStore.NotificationLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        hide-default-header
        thead-class="d-none"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Unique Id -->
        <template #cell(type)="data">
          <div v-if="data.item.type == 'UPDATE_CASE'">
            <CaseNotificationItem :data="data.item"></CaseNotificationItem>
          </div>
          <div v-else-if="data.item.type == 'WHATSAPP_CAUSELIST'">
            <HearingNotificationitem
              :data="data.item"
            ></HearingNotificationitem>
          </div>
          <div v-else-if="data.item.type == 'ORDER_NEW'">
            <OrderNotificationItem :data="data.item"></OrderNotificationItem>
          </div>
          <div v-else></div>
        </template>
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import CaseNotificationItem from '../components/CaseNotificationItem.vue'
import HearingNotificationitem from '../components/HearingNotificationitem.vue'
import OrderNotificationItem from '../components/OrderNotificationItem.vue'

// import store from '@/store'
import useNotificationList from './useNotificationList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,
    CaseNotificationItem,
    HearingNotificationitem,
    OrderNotificationItem,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    type: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      matterType: this.type,
      courtList: null
    }
  },
  beforeMount() {
    //   Court Setup
    this.$store.dispatch('courtStore/getAllCourts').then((response) => {
      console.log('Courts --> ', response)
      this.courtList = response
    })
  },
  methods: {
    onReadAllClick() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to mark all notifiaction as read.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('notificationStore/readAllNotification')
            .then((response) => {
              console.log('Notification --> ', response)
              this.refetchData()
              if (response.success) {
                this.$swal({
                  icon: 'success',
                  title: 'Success!',
                  text: 'All notification marked as read!',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              }else{
                this.$swal({
                  icon: 'danger',
                  title: 'Error!',
                  text: 'Failed to mark notifications as read!',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              }
            })
        }
      })
    },
  },

  setup(props) {
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useNotificationList({ type: props.type })

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
